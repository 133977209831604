<template>
  <div>
    <div id="view-header__teleport__single"></div>
    <div v-if="!notationRoutes.includes($route.name)">
      <div class="view-header view-header--protocol-details" v-if="pageTitle">
        <div class="view-header__header">
          <div id="header-action"></div>
        </div>
        <div class="view-header__footer">
          <Btn
            v-if="protocol.id"
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
              name: 'protocolRatingCalendar',
              params: {
                id: protocol.id,
              },
            }"
          />

          <h1 class="page-title">
            <template v-if="!task?.designation">
              {{ task?.type?.designation }}
            </template>
            <template v-else>
              {{ task?.type?.designation }} - {{ task?.designation }}
            </template>
            <div class="page-subtitle">Protocole : {{ protocol.titre }}</div>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial
        v-if="Object.keys(task).length && !notationRoutes.includes($route.name)"
        :tabs="tabs"
      />
      <router-view
        v-if="Object.keys(task).length"
        :task="task"
        :pageTitle="pageTitle"
        :isEditable="isEditable"
      ></router-view>
    </Section>
  </div>

  <NavigationDropdownProtocol />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownProtocol from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ExperimentalTaskView',

  components: {
    Btn,
    NavigationDropdownProtocol,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isEditable: true,
      task: {},
      protocol: {},
      notationRoutes: [
        'protocolExperimentalTaskVariableStudiedSettings',
        'protocolExperimentalTaskVariableStudiedEntryLevel',
      ],
      modal: {
        realizeEvent: false,
      },
      modalData: {},
      realizationDate: new Date().toISOString().split('T')[0],
    }
  },

  computed: {
    tabs() {
      return [
        {
          urlName: 'protocolExperimentalTaskGeneralInformation',
          text: 'Informations générales',
        },
        ...(this.task?.type?.uid === 'NOTATION'
          ? [
            {
              urlName: 'protocolExperimentalTaskVariableStudiedList',
              text: 'Variables étudiées',
            },
            {
              urlName: 'protocolExperimentalTaskVariableCalculated',
              text: 'Variables calculées',
            },
          ]
          : []), // Notation
      ]
    },
  },

  mounted() {
    this.getProtocole()
    this.getExperimentalTask()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    setEditable(step) {
      if (step) {
        this.isEditable = ['ENPREPARATION'].includes(step)
      } else {
        this.isEditable = true
      }
    },
    getProtocole() {
      this.fetchService.get(`protocole/${this.$route.params.id}`).then(
        (response) => {
          this.protocol = response.data
          this.isEditable = this.protocol?.statut?.uid !== 'ENCOURS'
        },
      )
    },
    getExperimentalTask() {
      this.fetchService
        .get(
          `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}`,
        )
        .then((reponse) => {
          this.task = reponse.data
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.view-header__header {
  display: flex;
  gap: $gutter-half;
}
</style>
